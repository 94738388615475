import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"

const Contact = ({ location }) => (
  <Layout location={location} seo={{ title: "Kontakt" }}>
    <PageHeader title="Kontakt" />
  </Layout>
)

export default Contact
